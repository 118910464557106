import { FormContext } from '../../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../../utils/state/initialStateFactory';
import { FormErrorsType, FormError } from '../../../../../types/errors';

export type RemoveErrors = (error: FormErrorsType[]) => void;

export function createRemoveErrorsAction({
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): RemoveErrors {
  return (errorsToRemove: FormErrorsType[]) => {
    const [state, setState] = getControllerState();
    const { errors: prevFormErrors } = state;

    const errors: FormError[] = prevFormErrors.filter(
      (error) => !errorsToRemove.includes(error.errorType),
    );
    setState({
      errors,
    });
  };
}
