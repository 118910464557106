import { FormContext } from '../../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../../utils/state/initialStateFactory';
import { FormError, FormErrorsType } from '../../../../../types/errors';
import { mapToArray } from '../../../../../utils';

export type ClearErrorByTypes = <
  ErrorType extends ArrayLike<Record<string, FormErrorsType>>,
>(
  errorTypes: ErrorType,
) => void;

export function createClearErrorByTypeAction({
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): ClearErrorByTypes {
  return (errorTypes) => {
    const [state, setState] = getControllerState();
    const { errors: prevFormErrors } = state;

    const errors = prevFormErrors.filter(
      (error: FormError) =>
        !mapToArray<FormErrorsType>(errorTypes[0]).includes(error.errorType),
    );

    setState({
      errors,
    });
  };
}
