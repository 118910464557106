import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  SlotService,
} from '../../../../utils/state/initialStateFactory';

export type UpdateServiceSlot = (
  partialSlotService: Partial<SlotService>,
  slotServiceKey: string,
) => void;

export function createUpdateServiceSlotAction({
  getControllerState,
}: ActionFactoryParams<FormState, FormContext>): UpdateServiceSlot {
  return async (
    partialSlotService: Partial<SlotService>,
    slotServiceKey: string,
  ) => {
    const [state, setState] = getControllerState();
    const { serviceData } = state;

    if (!serviceData.slotServices[slotServiceKey]) {
      throw new Error(
        `Update Slot Service Failed: ${slotServiceKey} are not existing in slotServices`,
      );
    }

    const existingSlotService = serviceData.slotServices[slotServiceKey];

    const updatedSlotService: SlotService = {
      ...existingSlotService,
      ...partialSlotService,
    };

    const updatedSlotServices = {
      ...serviceData.slotServices,
      [slotServiceKey]: updatedSlotService,
    };

    const updatedServiceData = {
      ...serviceData,
      slotServices: updatedSlotServices,
    };

    setState({ serviceData: updatedServiceData });
  };
}
