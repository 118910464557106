import { SelectedPaymentOption } from '@wix/ambassador-bookings-gateway/types';
import { CreateActionParams } from '../actions';
import { bookingsUouBookingsFormClicks } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  FormClickAction,
  FormClickActionValue,
} from '../../../../types/biLoggerTypes';
import { SlotService } from '../../../../utils/state/initialStateFactory';
import { getServiceSlotIdentifier } from '../../../../utils';

export type setSelectedPaymentType = (
  service: SlotService,
  selectedPaymentType: SelectedPaymentOption,
) => void;

export function createSetSelectedPaymentTypeAction({
  getControllerState,
  internalActions: { calculatePaymentDetails, updateServiceSlot },
  context: { biLogger },
}: CreateActionParams): setSelectedPaymentType {
  return async (
    service: SlotService,
    selectedPaymentType: SelectedPaymentOption,
  ) => {
    const [state] = getControllerState();

    updateServiceSlot(
      {
        selectedPaymentType,
      },
      getServiceSlotIdentifier(service.nestedSlot),
    );
    biLogger?.report(
      bookingsUouBookingsFormClicks({
        action: FormClickAction.Payment,
        value:
          selectedPaymentType === SelectedPaymentOption.MEMBERSHIP
            ? FormClickActionValue.PricingPlan
            : FormClickActionValue.SingleSessions,
      }),
    );

    await calculatePaymentDetails({
      couponCode: state.couponInfo.appliedCoupon?.couponCode,
    });
  };
}
