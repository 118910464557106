import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { resolveURLData } from './URLAdapter/URLDataResolver';
import { FormPageData } from '../../types/types';

export const getPageAPIData = async ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<FormPageData | undefined> => {
  try {
    return await resolveURLData({ flowAPI });
  } catch (error) {
    console.error('ERROR: failed to resolve page API data', error);
    return undefined;
  }
};
