import {
  BulkRequest,
  GetActiveFeaturesResponse,
  ListServicesResponse,
  Rate,
  ServicesCatalogServer,
} from '@wix/ambassador-services-catalog-server/http';
import type {
  PublicPlan,
  QueryPublicPlansResponse,
} from '@wix/ambassador-pricing-plans-v2-plan/types';
import { queryPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import type {
  NavigateToPackagePickerOptions,
  NavigateToCheckoutOptions,
} from '@wix/pricing-plans-tpa-api';
import {
  CalendarServer,
  ListSlotsRequest,
  ListSlotsResponse,
} from '@wix/ambassador-calendar-server/http';
import { getSession } from '@wix/ambassador-bookings-calendar-v1-session/http';
import { list } from '@wix/ambassador-pricing-plans-v2-benefit/http';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import {
  QueryAvailabilityRequest,
  ScheduleAvailability,
} from '@wix/ambassador-availability-calendar/types';
import {
  BookingsServer,
  IsAvailableResponse,
} from '@wix/ambassador-bookings-server/http';
import { previewPrice } from '@wix/ambassador-bookings-v2-price-info/http';
import {
  BookingLineItem,
  PreviewPriceRequest,
  PreviewPriceResponse,
} from '@wix/ambassador-bookings-v2-price-info/types';
import {
  Actor,
  BookedSchedule,
  ContactDetails,
  BookingsGateway,
  Platform,
  SelectedPaymentOption,
  ParticipantChoices,
} from '@wix/ambassador-bookings-gateway/http';
import {
  AddToCartResponse,
  AddToCurrentCartRequest,
  CreateCheckoutFromCurrentCartRequest,
  UpdateCartRequest,
  UpdateCartResponse,
} from '@wix/ambassador-ecom-v1-cart/types';
import {
  updateCurrentCart,
  createCheckoutFromCurrentCart as ecomCreateCheckoutFromCurrentCart,
  addToCurrentCart as ecomAddToCurrentCart,
} from '@wix/ambassador-ecom-v1-cart/http';
import {
  Membership,
  PaymentOptionType,
  TotalsCalculator,
} from '@wix/ambassador-totals-calculator/http';
import {
  LineItem,
  CalculateTotalsResponse,
} from '@wix/ambassador-totals-calculator/types';
import { CheckoutServer } from '@wix/ambassador-checkout-server/http';
import { CouponsServer } from '@wix/ambassador-coupons-server/http';
import { getServiceOptionsAndVariantsByServiceId } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/http';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { ServiceType } from '@wix/bookings-uou-types';
import { mapCatalogResourceResponseToStaffMember } from '@wix/bookings-uou-mappers';
import {
  CouponDetails,
  PaidPlans,
  Payments,
  Plan,
} from '@wix/ambassador-checkout-server/types';
import { CatalogData, IWithErrorHandingParams, OnError } from './types';
import { Service } from '../utils/mappers/service.mapper';
import { createSessionFromSlotAvailability } from './platformAdapters';
import {
  BOOKINGS_APP_DEF_ID,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { MembersNgApi } from '@wix/ambassador-members-ng-api/http';
import { mapBusinessResponseToBusinessInfo } from '../utils/mappers/businessInfo.mapper';
import {
  BusinessInfo,
  CartFlow,
  IncompleteFormSelectedSlot,
  ServicePaymentDetails,
} from '../types/types';
import { FormInfo } from '@wix/ambassador-bookings-server/types';
import { RateLabels } from '../utils/mappers/form-submission.mapper';
import { ServerErrorType } from '../types/errors';
import {
  ApiChannelType,
  Checkout as EcomCheckoutServer,
  CreateCheckoutResponse,
  CreateOrderRequest,
  CreateOrderResponse,
  UpdateCheckoutRequest,
} from '@wix/ambassador-checkout/http';
import {
  mapBookingsServerError,
  mapCouponServerError,
  mapCheckoutBookingError,
  mapCouponServerErrorsAndReporter,
  mapPreviewServerError,
} from '../utils/errors/errors';
import { IHttpClient, ReportError } from '@wix/yoshi-flow-editor';
import type { Experiments } from '@wix/yoshi-flow-editor';
import {
  CreateBookingRequest,
  CreateBookingResponse,
  CustomFormField,
} from '@wix/ambassador-bookings-gateway/types';
import { getSiteRolloutStatus } from '@wix/ambassador-bookings-v1-site-rollout-status/http';
import {
  ListEligibleMembershipsResponse,
  MembershipsSpiHost,
  SelectedMembership,
} from '@wix/ambassador-memberships-spi-host/http';
import { AvailabilityCalendar } from '@wix/ambassador-availability-calendar/http';
import {
  createConfig,
  CheckoutApiConfig,
  getCurrentCart,
  getBookingsLineItemsOptions,
  BookingsLineItemOption,
} from '@wix/bookings-checkout-api';
import { ExperimentsConsts } from '../consts/experiments';
import { FormNestedSlot, FormSelectedSlot } from '../types/formSelectedSlots';
import { getServiceSlotIdentifier } from '../utils';

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const BOOKINGS_SERVER_URL = '_api/bookings';
export const BOOKINGS_GATEWAY_URL = '_api/bookings-gateway';
export const ECOM_CHECKOUT_URL = 'ecom';
export const CALENDAR_SERVER_URL = '_api/calendar-server';
export const AVAILABILITY_CALENDAR_SERVER_URL = '_api/availability-calendar';
export const CHECKOUT_SERVER_URL = '_api/checkout-server';
export const MEMBERS_SERVER_API = '_api/members/v1/members';
export const COUPONS_SERVER_URL = '_api/coupons-server';
export const TOTALS_CALCULATOR = '_api/totals-calculator';
export const MEMBERSHIPS_SPI = '_api/memberships-spi-host';
export const BOOKINGS_PRICING_URL = '_api/bookings-pricing';
export const PAYMENT_SERVICES_URL = '_api/payment-services-web';

export const BOOKINGS_FES_BASE_DOMAIN = '/_api/bookings-viewer/visitor';
export const XSRF_HEADER_NAME = 'X-XSRF-TOKEN';
export const REVISION_HEADER_NAME = 'x-wix-site-revision';

export class FormApi {
  private experiments: Experiments;
  private wixSdkAdapter: WixOOISDKAdapter;
  private bookingsDataCapsule: BookingsDataCapsule;
  private reportError: ReportError;
  private httpClient: IHttpClient;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private bookingsServer: ReturnType<typeof BookingsServer>;
  private bookingsGatewayServer: ReturnType<typeof BookingsGateway>;
  private ecomCheckoutServer: ReturnType<typeof EcomCheckoutServer>;
  private calendarServer: ReturnType<typeof CalendarServer>;
  private availabilityCalendarServer: ReturnType<typeof AvailabilityCalendar>;
  private checkoutServer: ReturnType<typeof CheckoutServer>;
  private couponsServer: ReturnType<typeof CouponsServer>;
  private membersServer: ReturnType<typeof MembersNgApi>;
  private totalsCalculatorServer: ReturnType<typeof TotalsCalculator>;
  private membershipsServer: ReturnType<typeof MembershipsSpiHost>;

  constructor({
    httpClient,
    wixSdkAdapter,
    reportError,
    experiments,
    bookingsDataCapsule,
  }: {
    httpClient: IHttpClient;
    bookingsDataCapsule: BookingsDataCapsule;
    wixSdkAdapter: WixOOISDKAdapter;
    reportError: ReportError;
    experiments: Experiments;
  }) {
    this.experiments = experiments;
    this.httpClient = httpClient;
    this.bookingsDataCapsule = bookingsDataCapsule;
    this.wixSdkAdapter = wixSdkAdapter;
    this.reportError = reportError;
    const baseUrl = wixSdkAdapter.getServerBaseUrl();
    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.bookingsServer = BookingsServer(`${baseUrl}${BOOKINGS_SERVER_URL}`);
    this.bookingsGatewayServer = BookingsGateway(
      `${baseUrl}${BOOKINGS_GATEWAY_URL}`,
    );
    this.ecomCheckoutServer = EcomCheckoutServer(
      `${baseUrl}${ECOM_CHECKOUT_URL}`,
    );
    this.calendarServer = CalendarServer(`${baseUrl}${CALENDAR_SERVER_URL}`);
    this.checkoutServer = CheckoutServer(`${baseUrl}${CHECKOUT_SERVER_URL}`);
    this.couponsServer = CouponsServer(`${baseUrl}${COUPONS_SERVER_URL}`);
    this.membersServer = MembersNgApi(`${baseUrl}${MEMBERS_SERVER_API}`, {
      ignoredProtoHttpUrlPart: '/v1/members',
    });
    this.availabilityCalendarServer = AvailabilityCalendar(
      `${baseUrl}${AVAILABILITY_CALENDAR_SERVER_URL}`,
    );
    this.totalsCalculatorServer = TotalsCalculator(
      `${baseUrl}${TOTALS_CALCULATOR}`,
    );
    this.membershipsServer = MembershipsSpiHost(`${baseUrl}${MEMBERSHIPS_SPI}`);
  }

  async notifyOwnerNonPremiumEnrollmentAttempt() {
    return this.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/classes/nonPremium`,
      '',
      {
        headers: {
          [REVISION_HEADER_NAME]: this.wixSdkAdapter.getSiteRevision(),
          [XSRF_HEADER_NAME]: this.wixSdkAdapter.getCsrfToken(),
        },
      },
    );
  }

  async notifyOwnerNonPricingPlanEnrollmentAttempt(data: object) {
    return this.httpClient.post(
      `${BOOKINGS_FES_BASE_DOMAIN}/pricing-plans/invalidSetup`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          [REVISION_HEADER_NAME]: this.wixSdkAdapter.getSiteRevision(),
          [XSRF_HEADER_NAME]: this.wixSdkAdapter.getCsrfToken(),
        },
      },
    );
  }

  getAuthorization() {
    return this.wixSdkAdapter.getInstance();
  }

  getConfig(): CheckoutApiConfig {
    return createConfig({
      experiments: this.experiments,
      getAuthorization: () => this.getAuthorization(),
      getBaseUrl: () => this.wixSdkAdapter.getServerBaseUrl(),
      httpClient: this.httpClient,
    });
  }

  async getSessionById({ sessionId }: { sessionId: string }) {
    const sessionResponse = await this.httpClient.request(
      getSession({ id: sessionId }),
    );

    return sessionResponse.data.session;
  }

  async getTranslatedPricingDetails(publicPlan: PublicPlan) {
    const pricingPlansApi = await this.wixSdkAdapter.getPricingPlansApi();
    return pricingPlansApi.getTranslatedPricingDetails(publicPlan);
  }

  async navigateToPricePlanPackagePicker(
    options: NavigateToPackagePickerOptions,
  ) {
    const pricingPlansApi = await this.wixSdkAdapter.getPricingPlansApi();
    return pricingPlansApi.navigateToPackagePicker(options);
  }

  async navigateToPricePlanCheckout(options: NavigateToCheckoutOptions) {
    const pricingPlansApi = await this.wixSdkAdapter.getPricingPlansApi();
    return pricingPlansApi.navigateToCheckout(options);
  }

  async getPublicPlans() {
    return (
      await this.httpClient.request<QueryPublicPlansResponse>(
        queryPublicPlans({}),
      )
    )?.data;
  }

  async getPricingPlansBenefitList() {
    let listResponse;

    try {
      listResponse = await this.httpClient.request(list({}));
    } catch (e) {
      return [];
    }

    return listResponse?.data?.benefitsWithPlanInfo || [];
  }

  async getAppoitmentAvailability({
    serviceId,
    resourceId,
    locationId,
    startDate,
    endDate,
    timezone,
    beginningOfStartDate,
  }: {
    serviceId: string;
    resourceId: string;
    locationId?: string;
    startDate: string;
    endDate: string;
    timezone: string;
    beginningOfStartDate: string;
  }) {
    const request = {
      timezone,
      query: {
        filter: {
          serviceId,
          startDate: beginningOfStartDate,
          endDate,
          resourceId,
          'location.businessLocation.id': locationId ? locationId : undefined,
          bookable: true,
        },
      },
    } as QueryAvailabilityRequest;

    const availabilityServer =
      this.availabilityCalendarServer.AvailabilityCalendar()({
        Authorization: this.getAuthorization(),
      });

    const availabilityResponse = await availabilityServer.queryAvailability(
      request,
    );

    const startTimestamp = new Date(startDate).valueOf();
    const endTimestamp = new Date(endDate).valueOf();

    // we need to find the right slot because there is a time padding on the server
    const slot = availabilityResponse.availabilityEntries?.find(
      (availabilitySlot) =>
        new Date(availabilitySlot.slot?.startDate || 0).valueOf() ===
          startTimestamp &&
        new Date(availabilitySlot.slot?.endDate || 0).valueOf() ===
          endTimestamp,
    );

    return slot;
  }

  async getCatalogData({
    formSelectedSlot,
    onError,
  }: {
    formSelectedSlot?: FormSelectedSlot;
    onError?: OnError;
  } = {}): Promise<CatalogData> {
    const servicesCatalogService = this.catalogServer.Bulk();
    const bulkRequest: BulkRequest = this.createBulkRequest({
      formSelectedSlot,
      isPreview: this.wixSdkAdapter.isPreviewMode(),
    });
    const { data, error } = await this.withErrorBoundary({
      fn: async () => {
        const catalogData = await servicesCatalogService({
          Authorization: this.getAuthorization(),
        }).get(bulkRequest);
        const listResponse: ListServicesResponse =
          catalogData.responseServices!;
        const businessInfo: BusinessInfo = mapBusinessResponseToBusinessInfo(
          catalogData.responseBusiness!,
        );

        const activeFeatures: GetActiveFeaturesResponse =
          catalogData.responseBusiness!.activeFeatures!;

        const serviceList = listResponse?.services!.map((service) => {
          const slot = formSelectedSlot?.nestedSlots.find(
            (nestedSlot) => nestedSlot.serviceId === service?.service?.id,
          );
          const serviceResourcesIds =
            service?.resources?.map((resource) => resource.id) || [];
          const relevantResources =
            catalogData.responseResources!.resources!.filter(
              (resource) =>
                slot?.resource?.id ||
                serviceResourcesIds.includes(resource.resource!.id),
            );
          const staffMembers = relevantResources.map(
            mapCatalogResourceResponseToStaffMember,
          );

          return {
            service,
            staffMembers,
          };
        });

        return {
          serviceList,
          businessInfo,
          activeFeatures,
        };
      },
      mapError: (e) => ({
        error: ServerErrorType.INVALID_CATALOG_DATA,
        shouldReport: true,
      }),
      fallback: undefined,
    });

    if (error) {
      onError?.(error);
    }

    return data!;
  }

  async getSlots({
    firstSessionStart,
    lastSessionEnd,
    scheduleId,
    onError,
  }: {
    firstSessionStart: string;
    lastSessionEnd: string;
    scheduleId: string;
    onError?: OnError;
  }): Promise<ListSlotsResponse> {
    const { data, error } = await this.withErrorBoundary({
      fn: () => {
        const calendarService = this.calendarServer.CalendarService();
        // @ts-expect-error
        const fields: string[] = null;
        // @ts-expect-error
        const fieldsets: string[] = null;
        const filter = {
          from: new Date(firstSessionStart).toISOString(),
          to: new Date(lastSessionEnd).toISOString(),
          scheduleIds: [scheduleId],
        };
        const request: ListSlotsRequest = {
          query: {
            fieldsets,
            fields,
            sort: [],
            filter: JSON.stringify(filter),
          },
        };

        const calendarServiceResponse = calendarService({
          Authorization: this.getAuthorization(),
        });
        return calendarServiceResponse.listSlots(request);
      },
      mapError: (e) => {
        return {
          error: ServerErrorType.NO_LIST_SLOTS,
          shouldReport: true,
        };
      },
      fallback: {
        slots: undefined,
      },
    });

    if (error) {
      onError?.(error);
    }

    return data;
  }

  async getMemberDetails({
    id,
    onError,
  }: {
    id: string;
    onError?: OnError;
  }): Promise<Maybe<Member>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return;
    }
    const { data, error } = await this.withErrorBoundary({
      fn: async () => {
        const membersService = this.membersServer.Members();
        const { member } = await membersService({
          Authorization: this.getAuthorization(),
        }).getMember({
          fieldSet: 'FULL',
          id,
        });
        return member;
      },
      mapError: (e) => ({
        error: ServerErrorType.GENERIC_MEMBER_DETAILS_ERROR,
        shouldReport: true,
      }),
      fallback: undefined,
    });

    if (error) {
      onError?.(error);
    }

    return data;
  }

  async getLineItemOptionsFromCart(): Promise<BookingsLineItemOption[]> {
    return getBookingsLineItemsOptions(this.getConfig());
  }

  async isBookingsOnEcom(
    { onError } = { onError: (error: ServerErrorType) => {} },
  ): Promise<boolean> {
    const { data, error } = await this.withErrorBoundary({
      fn: async () => {
        const { data: response } = await this.httpClient.request(
          getSiteRolloutStatus({}),
        );
        return response!.siteRolloutStatus!.isBookingPlatformReady!;
      },
      mapError: (e) => ({
        error: ServerErrorType.CANNOT_FETCH_ECOM_ROLLOUT_STATUS,
        shouldReport: true,
      }),
      fallback: undefined,
    });
    if (error) {
      onError(error);
    }
    return data!;
  }

  async getOptionsAndVariantsData({
    formSelectedSlot,
  }: {
    formSelectedSlot: FormSelectedSlot;
    onError?: OnError;
  }): Promise<{ [key: string]: ServiceOptionsAndVariants | undefined }> {
    const res = await Promise.all(
      formSelectedSlot.nestedSlots.map(async (slot) => {
        const { data } = await this.withErrorBoundary({
          fn: async () => {
            const { data: response } = await this.httpClient.request(
              getServiceOptionsAndVariantsByServiceId({
                serviceId: slot.serviceId,
              }),
            );

            return response?.serviceVariants;
          },
          mapError: (e) => ({
            error: ServerErrorType.OPTIONS_AND_VARIANTS_FAILED,
            shouldReport: true,
          }),
          fallback: undefined,
        });

        return {
          slot,
          data,
        };
      }),
    );

    return res.reduce((acc, curr) => {
      acc[getServiceSlotIdentifier(curr.slot)] = curr.data;
      return acc;
    }, {} as any);
  }

  async getAvailability({
    scheduleId,
    onError,
  }: {
    scheduleId: string;
    onError?: OnError;
  }): Promise<IsAvailableResponse> {
    const { data, error } = await this.withErrorBoundary({
      fn: () =>
        this.bookingsServer
          .Availability()({ Authorization: this.getAuthorization() })
          .isAvailable({
            scheduleId,
            partySize: 1,
          }),
      mapError: (e) => ({
        error: ServerErrorType.NO_COURSE_AVAILABILITY,
        shouldReport: true,
      }),
      fallback: {
        capacity: 1,
        totalNumberOfParticipants: 1,
      },
    });

    if (error) {
      onError?.(error);
    }
    return data;
  }

  async getScheduleAvailability({
    scheduleId,
    onError,
  }: {
    scheduleId: string;
    onError?: OnError;
  }): Promise<ScheduleAvailability> {
    const { data, error } = await this.withErrorBoundary({
      fn: async () => {
        const { availability } = await this.availabilityCalendarServer
          .AvailabilityCalendar()({ Authorization: this.getAuthorization() })
          .getScheduleAvailability({ scheduleId });
        return availability;
      },
      mapError: (e) => ({
        error: ServerErrorType.NO_COURSE_AVAILABILITY,
        shouldReport: true,
      }),
      fallback: {
        openSpots: 1,
      },
    });

    if (error) {
      onError?.(error);
    }
    return data!;
  }

  async listMemberships({
    formSelectedSlot,
    onError,
  }: {
    formSelectedSlot: FormSelectedSlot;
    onError?: OnError;
  }): Promise<{ [key: string]: ListEligibleMembershipsResponse }> {
    const lineItems = formSelectedSlot.nestedSlots
      .filter((slot) => slot.startDate)
      .map((slot) => {
        return {
          id: slot.lineItemId,
          rootCatalogItemId: slot.serviceId,
          catalogReference: {
            catalogItemId: slot.serviceId,
            appId: BOOKINGS_APP_DEF_ID,
          },
          serviceProperties: {
            scheduledDate: new Date(slot.startDate),
            numberOfParticipants: 1,
          },
        };
      });

    let membersResponse: {
      data?: ListEligibleMembershipsResponse;
      error?: ServerErrorType;
    } = {};
    if (lineItems.length) {
      membersResponse = await this.withErrorBoundary({
        fn: () =>
          this.membershipsServer
            .MembershipsSpiHost()({ Authorization: this.getAuthorization() })
            .listEligibleMemberships({ lineItems }),
        mapError: (e) => {
          console.log('listMemberships error', e);
          return {
            error: ServerErrorType.GENERIC_PRICING_PLAN_ERROR,
            shouldReport: true,
          };
        },
        fallback: {
          eligibleMemberships: [],
        },
      });
    }
    if (membersResponse && membersResponse.error) {
      onError?.(membersResponse.error);
    }

    const result: { [key: string]: ListEligibleMembershipsResponse } = {};

    formSelectedSlot.nestedSlots.forEach((slot) => {
      result[getServiceSlotIdentifier(slot)] = {
        eligibleMemberships:
          membersResponse.data?.eligibleMemberships?.filter((membership) =>
            membership.lineItemIds?.includes(slot.lineItemId),
          ) || [],
        invalidMemberships:
          membersResponse.data?.invalidMemberships?.filter((membership) =>
            membership.membership?.lineItemIds?.includes(slot.lineItemId),
          ) || [],
        selectedMemberships:
          membersResponse.data?.selectedMemberships?.filter((membership) =>
            membership.lineItemIds?.includes(slot.lineItemId),
          ) || [],
      };
    });

    return result;
  }

  async getPricingPlanDetails({
    formSelectedSlot,
    onError,
  }: {
    formSelectedSlot: FormSelectedSlot;
    onError?: OnError;
  }): Promise<Maybe<PaidPlans>> {
    const { data, error } = await this.withErrorBoundary({
      fn: async () => {
        const { checkoutOptions } = await this.checkoutServer
          .CheckoutBackend()({
            Authorization: this.getAuthorization(),
          })
          .checkoutOptions({
            createSession: {
              scheduleOwnerId: formSelectedSlot.nestedSlots[0].serviceId,
              start: {
                timestamp: formSelectedSlot.nestedSlots[0].startDate,
              },
            },
            paymentSelection: {
              numberOfParticipants: 1,
            },
          });
        return checkoutOptions?.paidPlans;
      },
      mapError: (e) => ({
        error: ServerErrorType.GENERIC_PRICING_PLAN_ERROR,
        shouldReport: true,
      }),
      fallback: {
        plans: [],
      },
    });

    if (error) {
      onError?.(error);
    }
    return data;
  }

  async areCouponsAvailableForService(
    { onError } = { onError: (error: ServerErrorType) => {} },
  ): Promise<boolean> {
    const { data, error } = await this.withErrorBoundary({
      fn: async () => {
        const { hasCoupons } = await this.couponsServer
          .CouponsV2()({ Authorization: this.getAuthorization() })
          .hasCoupons({});
        return hasCoupons;
      },
      mapError: (e) => ({
        error: ServerErrorType.COUPON_SERVICE_UNAVAILABLE,
        shouldReport: true,
      }),
      fallback: false,
    });

    if (error) {
      onError(error);
    }
    return data;
  }

  async getPaymentsDetails({
    slot,
    numberOfParticipants,
    rate,
    serviceId,
    couponCode,
    email,
    isFixedPrice,
    onError,
  }: {
    slot: FormNestedSlot;
    numberOfParticipants: number;
    rate: Rate;
    serviceId: string;
    couponCode?: string;
    email?: string;
    isFixedPrice: boolean;
    onError?: OnError;
  }): Promise<Maybe<Payments>> {
    const { scheduleId, startDate, endDate } = slot;
    const { data, error } = await this.withErrorBoundary({
      fn: async () => {
        const { checkoutOptions } = await this.checkoutServer
          .CheckoutBackend()({ Authorization: this.getAuthorization() })
          .checkoutOptions({
            scheduleId,
            couponCode,
            createSession: {
              rate,
              scheduleOwnerId: serviceId,
              start: {
                timestamp: startDate,
              },
              end: {
                timestamp: endDate,
              },
            },
            paymentSelection: {
              numberOfParticipants,
              ...(isFixedPrice ? { rateLabel: RateLabels.GENERAL } : {}),
            },
            ...(email && { email }),
          });
        return checkoutOptions?.payments;
      },
      mapError: (e) => mapCouponServerErrorsAndReporter(JSON.stringify(e)),
      fallback: undefined,
    });

    if (error) {
      onError?.(error as unknown as ServerErrorType);
    }
    return data;
  }

  async getCartLineItems() {
    const cartLineItems = await getCurrentCart(this.getConfig());
    return cartLineItems;
  }

  async addToCart({
    selectedPaymentType,
    paymentDetails,
    lineItemId,
    bookingId,
    selectedMembership,
    onError,
  }: {
    selectedPaymentType: SelectedPaymentOption;
    paymentDetails: ServicePaymentDetails;
    lineItemId: string;
    bookingId?: string;
    selectedMembership?: SelectedMembership;
    onError?: OnError;
  }) {
    const paymentOption = this.getPaymentOption(
      selectedPaymentType,
      paymentDetails.minCharge,
    );
    const addToCurrentCartRequest: AddToCurrentCartRequest = {
      lineItems: [
        {
          id: lineItemId,
          paymentOption,
          ...(selectedMembership?.id
            ? {
                selectedMembership: {
                  id: selectedMembership?.id,
                  appId: selectedMembership?.appId,
                },
              }
            : {}),
          ...(paymentDetails.price
            ? {
                price: {
                  amount: String(paymentDetails.price),
                },
              }
            : {}),
          ...(paymentDetails.priceText
            ? {
                priceDescription: {
                  original: paymentDetails.priceText,
                },
              }
            : {}),
          quantity: 1,
          catalogReference: {
            catalogItemId: bookingId,
            appId: BOOKINGS_APP_DEF_ID,
          },
        },
      ],
    };
    const {
      data: addToCurrentCartResponse,
      error: addToCurrentCartResponseError,
    } = await this.withErrorBoundary({
      fn: () => this.addToCurrentCart(addToCurrentCartRequest),
      mapError: (e) => ({
        error: mapCheckoutBookingError(e?.response),
        shouldReport: true,
      }),
      fallback: {},
    });

    if (addToCurrentCartResponseError) {
      onError?.(addToCurrentCartResponseError);
    }
    if (addToCurrentCartResponse.cart) {
      return {
        addToCurrentCartResponse,
      };
    }
    return {
      addToCurrentCartResponse: {},
    };
  }

  async generalBookingCheckoutFlow({
    createBookingResponse,
    lineItemId,
    bookingId,
    appliedCoupon,
    contactDetails,
    selectedMembership,
    onError,
    isCart,
    cartFlow,
    country,
  }: {
    createBookingResponse: CreateBookingResponse;
    lineItemId: string;
    bookingId?: string;
    appliedCoupon?: CouponDetails;
    contactDetails: ContactDetails;
    selectedMembership?: Membership;
    onError?: OnError;
    isCart?: boolean;
    cartFlow?: CartFlow;
    country: string;
  }) {
    if (bookingId) {
      const { data: createCheckoutResponse, error: createCheckoutError } =
        await this.withErrorBoundary({
          fn: () =>
            this.createCheckout(
              '00000000-0000-0000-0000-000000000001', // !!! TODO support multiple slots with ecom new checkout lineitemid from arguments
              bookingId,
              appliedCoupon,
              contactDetails,
              selectedMembership,
              country,
            ),
          mapError: (e) => ({
            error: mapCheckoutBookingError(e?.response),
            shouldReport: true,
          }),
          fallback: {},
        });

      if (createCheckoutError) {
        onError?.(createCheckoutError);
      }

      const isHideCouponInFormPageEnabled = this.experiments.enabled(
        ExperimentsConsts.HideCouponInFormPage,
      );

      if (
        isHideCouponInFormPageEnabled ||
        (isCart && cartFlow === CartFlow.CHECKOUT)
          ? this.isFreeOrPricePlanCheckoutFlow(createCheckoutResponse)
          : this.isOfflineCheckoutFlow(createCheckoutResponse)
      ) {
        const { data: createOrderResponse, error: createOrderError } =
          await this.withErrorBoundary({
            fn: () => this.createOrder(createCheckoutResponse),
            mapError: (e) => ({
              error: mapCheckoutBookingError(e?.response),
              shouldReport: true,
            }),
            fallback: {},
          });

        if (createOrderError) {
          onError?.(createOrderError);
        }

        return {
          createCheckoutResponse: createOrderResponse,
          createBookingResponse,
        };
      } else {
        return {
          createBookingResponse,
          createCheckoutResponse,
        };
      }
    }
    return {
      createBookingResponse: {},
      createCheckoutResponse: {},
    };
  }

  async checkoutBooking({
    slot,
    service,
    contactDetails,
    additionalFields,
    numberOfParticipants,
    sendSmsReminder,
    appliedCoupon,
    selectedPaymentType,
    selectedMembership,
    participantsChoices,
    totalParticipants,
    isCart,
    v2Availability,
    paymentDetails,
    cartFlow,
    onError,
    country,
  }: {
    slot: FormNestedSlot;
    service: Service;
    contactDetails: ContactDetails;
    additionalFields: CustomFormField[];
    numberOfParticipants?: number;
    sendSmsReminder?: boolean;
    appliedCoupon?: CouponDetails;
    selectedPaymentType: SelectedPaymentOption;
    selectedMembership?: Membership;
    participantsChoices?: ParticipantChoices;
    totalParticipants?: number;
    isCart?: boolean;
    v2Availability?: boolean;
    paymentDetails: ServicePaymentDetails;
    cartFlow?: CartFlow;
    onError?: OnError;
    country: string;
  }): Promise<{
    createBookingResponse?: CreateBookingResponse;
    createCheckoutResponse?: CreateCheckoutResponse | CreateOrderResponse;
    addToCurrentCartResponse?: AddToCartResponse;
  }> {
    const { data: createBookingResponse, error: createBookingsError } =
      await this.withErrorBoundary({
        fn: () =>
          this.createBookings(
            service,
            slot,
            contactDetails,
            additionalFields,
            numberOfParticipants,
            participantsChoices,
            totalParticipants,
            sendSmsReminder,
            selectedMembership,
            selectedPaymentType,
            v2Availability,
          ),
        mapError: (e) => ({
          error: mapCheckoutBookingError(e?.response),
          shouldReport: true,
        }),
        fallback: {},
      });

    if (createBookingsError) {
      onError?.(createBookingsError);
    }

    const bookingId = createBookingResponse?.booking?.id;
    if (isCart && bookingId && cartFlow !== CartFlow.CHECKOUT) {
      try {
        return this.addToCart({
          selectedPaymentType,
          paymentDetails,
          lineItemId: slot.lineItemId!,
          bookingId,
          selectedMembership,
          onError,
        });
      } catch (e: any) {
        if (e.httpStatus === 404) {
          return this.addToCart({
            selectedPaymentType,
            paymentDetails,
            lineItemId: slot.lineItemId!,
            bookingId,
            selectedMembership,
            onError,
          });
        } else {
          onError?.(e.message);
        }
      }
    } else {
      return this.generalBookingCheckoutFlow({
        createBookingResponse,
        lineItemId: slot.lineItemId!,
        bookingId,
        appliedCoupon,
        contactDetails,
        selectedMembership,
        onError,
        isCart,
        cartFlow,
        country,
      });
    }
    return {
      createBookingResponse: {},
      createCheckoutResponse: {},
    };
  }

  async addToCurrentCart(addToCurrentCartRequest: AddToCurrentCartRequest) {
    return (
      await this.httpClient.request(
        ecomAddToCurrentCart(addToCurrentCartRequest),
      )
    ).data;
  }

  async createCheckoutFromCurrentCart(
    createCheckoutFromCurrentCartRequest: CreateCheckoutFromCurrentCartRequest,
  ) {
    return (
      await this.httpClient.request(
        ecomCreateCheckoutFromCurrentCart(createCheckoutFromCurrentCartRequest),
      )
    ).data;
  }

  async createOrderFromCheckout(createOrderRequest: CreateOrderRequest = {}) {
    return this.ecomCheckoutServer
      .CheckoutService()({
        Authorization: this.getAuthorization(),
      })
      .createOrder(createOrderRequest);
  }

  private async createOrder(createCheckoutResponse: CreateCheckoutResponse) {
    return this.ecomCheckoutServer
      .CheckoutService()({
        Authorization: this.getAuthorization(),
      })
      .createOrder({
        id: createCheckoutResponse?.checkout?.id,
      });
  }

  async updateCart({
    cartInfo,
  }: UpdateCartRequest): Promise<UpdateCartResponse> {
    return (
      await this.httpClient.request(
        updateCurrentCart({
          cartInfo,
        }),
      )
    ).data;
  }

  async updateCheckout(updateCheckoutRequest: UpdateCheckoutRequest) {
    return this.ecomCheckoutServer
      .CheckoutService()({
        Authorization: this.getAuthorization(),
      })
      .updateCheckout(updateCheckoutRequest);
  }

  private async createCheckout(
    lineItemId: string,
    bookingId: string,
    appliedCoupon: CouponDetails | undefined,
    contactDetails: ContactDetails,
    selectedMembership: Membership | undefined,
    country: string,
  ) {
    const isSendAddressToEcomCheckoutAndCartEnabled = this.experiments.enabled(
      ExperimentsConsts.SendAddressToEcomCheckoutAndCart,
    );

    return this.ecomCheckoutServer
      .CheckoutService()({
        Authorization: this.getAuthorization(),
      })
      .createCheckout({
        channelType: ApiChannelType.WEB,
        lineItems: [
          {
            quantity: 1,
            id: lineItemId,
            catalogReference: {
              catalogItemId: bookingId,
              appId: BOOKINGS_APP_DEF_ID,
            },
          },
        ],
        couponCode: appliedCoupon?.couponCode,
        checkoutInfo: {
          billingInfo: {
            contactDetails: this.mapContactDetails(contactDetails),
            ...(isSendAddressToEcomCheckoutAndCartEnabled &&
            contactDetails.fullAddress &&
            country
              ? { address: { ...contactDetails.fullAddress, country } }
              : {}),
          },
          buyerInfo: {
            email: contactDetails.email,
          },
          membershipOptions: {
            selectedMemberships: {
              memberships: selectedMembership
                ? [
                    {
                      id: selectedMembership.id,
                      appId: selectedMembership.appId,
                      lineItemIds: [lineItemId],
                    },
                  ]
                : [],
            },
          },
        },
      });
  }

  private async createBookings(
    service: Service,
    slot: FormNestedSlot,
    contactDetails: ContactDetails,
    additionalFields: CustomFormField[],
    numberOfParticipants: number | undefined,
    participantsChoices: ParticipantChoices | undefined,
    totalParticipants: number | undefined,
    sendSmsReminder: boolean | undefined,
    selectedMembership: Membership | undefined,
    selectedPaymentType: SelectedPaymentOption,
    v2Availability?: boolean,
  ) {
    const isCourse = service.type === ServiceType.COURSE;
    const createBookingRequest: CreateBookingRequest = {
      ...(isCourse
        ? {
            schedule: this.mapBookedSchedule({ service, slot }),
          }
        : { slot }),
      ...(v2Availability ? { v2Availability } : {}),
      contactDetails,
      additionalFields,
      numberOfParticipants,
      ...(participantsChoices ? { participantsChoices } : {}),
      ...(totalParticipants ? { totalParticipants } : {}),
      sendSmsReminder,
      selectedPaymentOption: selectedMembership
        ? SelectedPaymentOption.MEMBERSHIP
        : selectedPaymentType,
      participantNotification: {
        notifyParticipants: true,
      },
      bookingSource: {
        actor: Actor.CUSTOMER,
        platform: Platform.WEB,
      },
    };
    return this.bookingsGatewayServer
      .BookingsGateway()({ Authorization: this.getAuthorization() })
      .createBooking(createBookingRequest);
  }

  async calculateTotalPrice({
    lineItems,
    couponCode,
    email,
    onError,
  }: {
    lineItems: LineItem[];
    couponCode?: string;
    email?: string;
    onError?: OnError;
  }): Promise<CalculateTotalsResponse> {
    const { data, error } = await this.withErrorBoundary({
      fn: async () => {
        const calculateTotalsResponse = await this.totalsCalculatorServer
          .TotalsCalculator()({
            Authorization: this.getAuthorization(),
          })
          .calculateTotals({
            calculateTax: true,
            couponCode,
            buyerEmail: email,
            lineItems,
          });

        const couponError =
          calculateTotalsResponse?.calculationErrors?.couponCalculationError
            ?.applicationError?.code ||
          calculateTotalsResponse?.calculationErrors?.couponCalculationError
            ?.validationError?.fieldViolations?.[0].description;
        if (couponError) {
          throw mapCouponServerError(couponError);
        }
        return calculateTotalsResponse;
      },
      mapError: (e) => mapCouponServerErrorsAndReporter(JSON.stringify(e)),
      fallback: {},
    });

    if (error) {
      onError?.(error as unknown as ServerErrorType);
    }

    return data;
  }

  async previewPrice({
    bookingLineItems,
    onError,
  }: {
    bookingLineItems: BookingLineItem[];
    onError?: OnError;
  }): Promise<PreviewPriceResponse> {
    const { data, error } = await this.withErrorBoundary({
      fn: async () => {
        const previewPriceRequest: PreviewPriceRequest = {
          bookingLineItems,
        };
        const { data: previewPriceResponse } = await this.httpClient.request(
          previewPrice(previewPriceRequest),
        );
        return previewPriceResponse;
      },
      mapError: (e) => ({
        error: mapPreviewServerError(JSON.stringify(e)),
        shouldReport: true,
      }),
      fallback: {},
    });

    if (error) {
      onError?.(error as unknown as ServerErrorType);
    }

    return data;
  }

  async book({
    service,
    formInfo,
    formSelectedSlot,
    selectedPlan,
    sendSmsReminder,
    appliedCoupon,
    onError,
  }: {
    service: Service;
    formInfo: FormInfo;
    formSelectedSlot: FormSelectedSlot;
    selectedPlan?: Plan;
    sendSmsReminder?: boolean;
    appliedCoupon?: CouponDetails;
    onError?: OnError;
  }) {
    const { data, error } = await this.withErrorBoundary({
      fn: async () => {
        const serviceTypeSpecificPayload =
          this.serviceTypeDependentRequestPayload(service, formSelectedSlot);
        return this.bookingsServer
          .Bookings()({ Authorization: this.getAuthorization() })
          .book({
            ...serviceTypeSpecificPayload,
            formInfo,
            ...(appliedCoupon ? { couponCode: appliedCoupon.couponCode } : {}),
            planSelection: selectedPlan?.paidPlan,
            sendSmsReminder,
            notifyParticipants: true,
          });
      },
      mapError: (e) => ({
        error: mapBookingsServerError(e?.response),
        shouldReport: true,
      }),
      fallback: {},
    });

    if (error) {
      onError?.(error as unknown as ServerErrorType);
    }

    return data;
  }

  private serviceTypeDependentRequestPayload(
    service: Service,
    slot: FormSelectedSlot,
  ) {
    switch (service.type) {
      case ServiceType.INDIVIDUAL:
        return {
          createSession: createSessionFromSlotAvailability(slot),
        };
      case ServiceType.GROUP:
        return {
          bySessionId: {
            sessionId: slot.nestedSlots[0].sessionId,
          },
        };
      case ServiceType.COURSE:
        return {
          scheduleId: service.scheduleId,
        };
    }
  }

  private createBulkRequest({
    formSelectedSlot,
    isPreview,
  }: {
    formSelectedSlot?: IncompleteFormSelectedSlot;
    isPreview?: boolean;
  }): BulkRequest {
    // TODO: Support Multi Services - make sure this logic works once the server is updated
    const filter: any = {
      ...(formSelectedSlot && formSelectedSlot?.nestedSlots.length > 1
        ? {
            policy: {
              default: true,
            },
          }
        : {}),
    };
    let isMissingResource = false;
    const resourceIds =
      formSelectedSlot?.nestedSlots.map((slot) => {
        if (!slot.resource?.id) {
          isMissingResource = true;
        }
        return slot.resource?.id;
      }) || [];

    if (isMissingResource || resourceIds.length === 0) {
      filter['resource.tags'] = { $hasSome: ['staff'] };
    } else {
      filter['resource.id'] = { $in: resourceIds };
    }

    const serviceIds = formSelectedSlot?.nestedSlots
      .map((slot) => slot.serviceId)
      .filter((slot) => slot);

    return {
      ...(serviceIds?.length
        ? {
            requestServices: {
              includeDeleted: false,
              query: {
                filter: JSON.stringify({
                  'service.id': {
                    $in: serviceIds,
                  },
                }),
              },
            },
          }
        : {}),
      requestListResources: {
        includeDeleted: true,
        query: {
          filter: JSON.stringify(filter),
          fields: ['resource.id', 'resource.name'],
          fieldsets: [],
          paging: {
            limit: 1000,
          },
          sort: [],
        },
      },
      requestBusiness: {
        suppressNotFoundError: false,
      },
    };
  }

  private isOfflineCheckoutFlow(
    createCheckoutResponse: CreateCheckoutResponse,
  ) {
    const payNowAmount =
      createCheckoutResponse?.checkout?.payNow?.total?.amount;
    return payNowAmount && Number(payNowAmount) === 0;
  }

  private isFreeOrPricePlanCheckoutFlow(
    createCheckoutResponse: CreateCheckoutResponse,
  ) {
    const payNowAmount =
      createCheckoutResponse?.checkout?.payNow?.total?.amount;
    const payLaterAmount =
      createCheckoutResponse?.checkout?.payLater?.total?.amount;
    return (
      payNowAmount &&
      Number(payNowAmount) === 0 &&
      payLaterAmount &&
      Number(payLaterAmount) === 0
    );
  }

  private mapBookedSchedule({
    service,
    slot,
  }: {
    service: Service;
    slot: FormNestedSlot;
  }): BookedSchedule {
    return {
      serviceId: service.id,
      scheduleId: service.scheduleId,
      timezone: slot.timezone,
    };
  }

  private isPhoneValid(phone: string): boolean {
    if (!phone) {
      return false;
    }
    const phoneValidatorRegEx = /^[\d\s+\-().]+$/;
    return (phone.match(phoneValidatorRegEx)?.length || 0) > 0;
  }

  public mapContactDetails(contactDetails: ContactDetails): ContactDetails {
    const isMergeFirstNameAndLastNameWhenLastNameFieldDontExistEnabled =
      this.experiments.enabled(
        ExperimentsConsts.MergeFirstNameAndLastNameWhenLastNameFieldDontExist,
      );
    if (!this.isPhoneValid(contactDetails.phone!)) {
      delete contactDetails.phone;
    }
    if (contactDetails.lastName) {
      return contactDetails;
    }
    const nameParts = contactDetails?.firstName?.trim().split(' ');
    const firstName =
      isMergeFirstNameAndLastNameWhenLastNameFieldDontExistEnabled
        ? nameParts?.slice(0, -1).join(' ')
        : nameParts?.[0];
    const lastName =
      (nameParts?.length || 0) > 1
        ? isMergeFirstNameAndLastNameWhenLastNameFieldDontExistEnabled
          ? nameParts?.[nameParts.length - 1]
          : nameParts?.[1]
        : undefined;

    return {
      ...contactDetails,
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
    };
  }

  public getPaymentOption(
    selectedPaymentType: SelectedPaymentOption,
    deposit: Maybe<number>,
  ) {
    if (deposit) {
      return PaymentOptionType.DEPOSIT_ONLINE;
    } else {
      return selectedPaymentType === SelectedPaymentOption.ONLINE
        ? PaymentOptionType.FULL_PAYMENT_ONLINE
        : PaymentOptionType.FULL_PAYMENT_OFFLINE;
    }
  }

  private withErrorBoundary<ResponseType, ErrorType>({
    fn,
    mapError,
    fallback,
  }: IWithErrorHandingParams<ResponseType, ErrorType>) {
    return fn()
      .then((data) => ({ data, error: undefined }))
      .catch((e) => {
        const { shouldReport, error } = mapError(e);
        if (shouldReport) {
          this.reportError(e as Error);
        }
        return {
          data: fallback,
          error,
        };
      });
  }
}

export const generateLineItemId = () => {
  // Ecom's GUID format
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};
