import { FormSelectedSlot } from '../types/formSelectedSlots';
import { Session } from '@wix/ambassador-bookings-server/types';

export function createSessionFromSlotAvailability(
  formSelectedSlot: FormSelectedSlot,
): Session {
  const slot = formSelectedSlot.nestedSlots[0];
  const affectedSchedules = [
    {
      scheduleId: slot.resource!.scheduleId,
    },
  ];
  const { scheduleId, startDate, endDate } = slot;
  const location = formSelectedSlot.location;
  return {
    affectedSchedules,
    scheduleId,
    start: {
      timestamp: startDate,
    },
    end: {
      timestamp: endDate,
    },
    location: {
      locationType: location?.locationType as any,
      ...(location?.id
        ? {
            businessLocation: { id: location?.id },
          }
        : {}),
    },
  };
}
